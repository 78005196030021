import { dev } from '$app/environment';
import { handleErrorWithSentry, setTag, init, replayIntegration } from '@sentry/sveltekit';
import {
  PUBLIC_SENTRY_DSN,
  PUBLIC_METAFY_ENV,
  PUBLIC_METAFY_DEPLOY_VERSION,
  PUBLIC_STAGING_ID
} from '$env/static/public';

// Initialize Sentry
init({
  dsn: PUBLIC_SENTRY_DSN,
  environment: PUBLIC_METAFY_ENV,
  release: PUBLIC_METAFY_DEPLOY_VERSION,
  sampleRate: 0.25,
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0, // Always send the recorded replay on an error
  profilesSampleRate: 0.25,
  allowUrls: [/metafy\.gg/], // Don't report errors from scripts served outside metafy
  ignoreErrors: [
    'NotAllowedError',
    'AbortError',
    'Load failed',
    'Response not successful',
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Importing a module script failed.',
    'error loading dynamically imported module',
    'Event missing',
    'There was a network error.',
    'Connection is canceled.',
    'Object captured as exception with keys',
    'Invariant Violation'
  ],
  integrations: [
    replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [window.location.origin]
    })
  ],
  beforeSend(event, hint) {
    if (dev || PUBLIC_METAFY_ENV === 'development') {
      console.error(hint.originalException || hint.syntheticException);
      return null; // Don't send to Sentry
    } else {
      return event;
    }
  },
  beforeSendTransaction(transaction) {
    if (dev || PUBLIC_METAFY_ENV === 'development') {
      return null; // Don't send to Sentry
    }
    return transaction;
  }
});

// Tag if this is a staging server
if (PUBLIC_METAFY_ENV === 'staging') {
  setTag('staging_id', PUBLIC_STAGING_ID);
}

// Handle error reporting on the client
export const handleError = handleErrorWithSentry(function ({ error }) {
  if (dev) {
    return { message: (error as Error).message, stack: (error as Error).stack, frame: (error as any).frame };
  } else {
    return { message: (error as Error).message };
  }
});
